<template>
  <div>
    <div class="container">
      <div class="loading" v-show="savedAccount">
        Welcome back!<br>Loading your account...
        <span v-show="redirectTooSlow"><br>Taking too long? <a href="" v-on:click="resetLogin">Click here to log in again</a></span>
      </div>
      <section class="form animated flipInX" v-show="!savedAccount">
        <div v-if="accounts !== false && accounts.length > 1">
          <h2>We found more than one account!</h2>
          <p>Please choose one:</p>
          <ul v-for="account in accounts" :key="account.url">
            <li><button v-on:click="authenticateAccount(account)">{{ account.company }}<br>{{ account.subdomain }}.{{ account.host }}</button></li>
          </ul>
        </div>
        <div v-else>
          <img :src="logo" class="logo" v-on:click="triggerDevMode">
          <h2>Login To Your Account</h2>
          <div v-if="accounts !== false && accounts.length === 0" class="error">
            Invalid username or password.
          </div>
          <form v-on:submit="submit" class="loginbox" autocomplete="off">
            <input type="email" v-model="email" placeholder="Email Address">
            <input type="password" v-model="password" placeholder="Password">
            <button type="submit" :disabled="email === '' || password === ''">
              <span v-if="submitting">Logging in...</span>
              <span v-else>Log in</span>
            </button>
          </form>
          <div class="reset-password"><a href="/forgot-password/">Reset Password</a></div>
          <div class="demo"><a href="#" v-on:click="tryDemo">Want to try the demo account?</a></div>
          <div class="clear-cache" v-if="localMode || devMode"><a href="https://dev.elvanto.co">Dev Account</a></div>
          <div class="clear-cache" v-if="localMode || devMode"><a href="gonative://webview/clearCache">Clear Cache</a></div>
        </div>
      </section>
    </div>
    <div class="create-account" v-show="!savedAccount && !accounts">
      Want Elvanto for your church?<br><a href="https://signup.tithe.ly/" target="_blank">Click here to create an account</a>
    </div>
    <form :action="selectedAccountUrl" method="post" id="regionForm">
      <input type="hidden" name="login_username" v-model="email">
      <input type="hidden" name="login_password" v-model="password">
      <input type="hidden" name="remember_me" value="year">
      <input type="hidden" name="login_to" value="admin">
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import logo from '../assets/logo.png'

export default {
  name: 'Login',
  data() {
    return {
      redirectTooSlow: false,
      savedAccount: true,
      submitting: false,
      accounts: false,
      email: '',
      password: '',
      selectedAccountUrl: '',
      logo: logo,
      logoTaps: 0
    }
  },
  mounted() {
    // Remove saved account if logout hash exists
    if (window.location.hash === '#logout') {
      localStorage.removeItem('account')
    }

    // Redirect to the account if it exists
    let accountUrl = localStorage.getItem('account')
    if (accountUrl) {
      this.savedAccount = accountUrl
      setTimeout(() => this.redirectTooSlow = true, 6000)
      setTimeout(() => window.location.href = accountUrl, 500)
    } else {
      this.savedAccount = false
    }
  },
  methods: {
    triggerDevMode: function () {
      this.logoTaps++;
    },
    submit: function (e) {
      e.preventDefault()

      let vm = this

      this.accounts = false

      if (this.email === '' || this.password === '') {
        return
      }

      this.submitting = true

      let url = 'https://tools.elvanto.com'
      const patt = new RegExp(/localhost/)
      if (patt.test(window.location.href)) {
        url = window.location.origin + '/api/password/login'
      }

      let params = new URLSearchParams()
      params.append('email', this.email)
      params.append('password', this.password)

      axios
          .post(
              url + '/preauth',
              params,
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              }
          ).then(response => {
            const accounts = response.data.data
            if (accounts.length === 0) {
              vm.accounts = []
              vm.submitting = false
            } else if (accounts.length === 1) {
              vm.authenticateAccount(accounts[0])
            } else if (accounts.length > 1) {
              vm.accounts = accounts
              vm.submitting = false
            }
          })
          .catch(() => {
            vm.accounts = []
            vm.submitting = false
          })
    },
    tryDemo: function (e) {
      e.preventDefault()

      let url = 'https://demo.elvanto.com'
      const patt = new RegExp(/localhost/)
      if (patt.test(window.location.href)) {
        url = 'http://demo-tithelychms:8105'
      }

      localStorage.setItem('account', url)

      window.location.href = url;
    },
    authenticateAccount: function (account) {
      localStorage.setItem('account', account.url)

      this.selectedAccountUrl = account.url + '/login'

      setTimeout(() => {
        document.getElementById('regionForm').submit()
      }, 500)
    },
    resetLogin: function (e) {
      e.preventDefault()

      this.savedAccount = false
      localStorage.removeItem('account')

      if (navigator.userAgent.indexOf('gonative') > -1 || navigator.userAgent.indexOf('median') > -1) {
        window.location.href = 'gonative://webview/clearCache'
      }
    }
  },
  computed: {
    localMode: function () {
      const pattern = new RegExp(/localhost/)
      if (pattern.test(window.location.href)) {
        return true
      }
      return false
    },
    devMode: function() {
      return this.logoTaps === 6
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/style.scss';
</style>
