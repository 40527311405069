<template>
  <div id="app">
    <component :is="viewComponent" />
  </div>
</template>

<script>
import routes from './routes'

export default {
  name: 'App',
  data() {
    return {
      currentRoute: window.location.pathname
    }
  },
  computed: {
    viewComponent () {
      return routes[this.currentRoute]
    }
  },
  beforeMount() {
    window.addEventListener('popstate', () => {
      this.currentRoute = window.location.pathname
    })
  }
}

// Change status bar color. This is a blocking change so needs to be run last
if (navigator.userAgent.indexOf('gonative') > -1 || navigator.userAgent.indexOf('median') > -1) {
  setTimeout(function() {
    window.location.href = 'gonative://statusbar/set?style=light&color=1d242f';
  }, 250);
}
</script>

<style>
body {
  background: #1d242f;
  margin: 0;
  padding: 0;
}
#app {
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, System Font, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
