<template>
  <div>
    <div class="container">
      <section class="form animated flipInX">
        <div v-if="!resetSubmitted">
          <img :src="logo" class="logo">
          <h2>Forgot your Password?</h2>
          <div v-if="error" class="error">An error occured, please try again.</div>
          <form @submit.prevent="submit" class="resetbox" autocomplete="off">
            <input type="email" v-model="email" placeholder="Email Address">
            <button type="submit" :disabled="email === ''">
              <span v-if="submitting">Sending reset email...</span>
              <span v-else>Reset Password</span>
            </button>
          </form>
        </div>
        <div v-else>
          <img :src="logo" class="logo">
          <h2>An email has been sent to {{email}}</h2>
          <p>If you did not receive one please, check that you submitted the right email address and check your spam folder.</p>
        </div>
        <div class="go-back"><a href="/">Return to Login</a></div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import logo from '../assets/logo.png'

export default {
  name: 'ForgotPassword',
  data() {
    return {
      submitting: false,
      resetSubmitted: false,
      error: false,
      email: '',
      logo: logo,
      logoTaps: 0
    }
  },
  mounted() {
      this.resetSubmitted = false
      this.submitting = false
  },
  methods: {
    submit: function () {
      this.submitting = true

      let url = `https://tools.elvanto.com/findmydomain?email=${this.email}&reset=true`

      const patt = new RegExp(/localhost/)

      if (patt.test(window.location.href)) {
        url = window.location.origin + '/api/password/reset/' + this.email
      }

      axios
          .get(
              url,
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              }
          ).then(() => {
            this.submitting = false
            this.error = false
            this.resetSubmitted = true

            if (navigator.userAgent.indexOf('gonative') > -1 || navigator.userAgent.indexOf('median') > -1) {
              window.location.href = 'gonative://webview/clearCache'
            }
          })
          .catch(() => {
            this.submitting = false
            this.error = true
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/style.scss';
</style>
